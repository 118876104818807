
.chart-head {
  font-size: 1.3em;
  padding: 4px 8px;
}

.gantt-chart-wrap {
  padding: 1em;
  border: 1px solid #000;
  margin: 1em;
  min-height: 500px;
}

.view-modes {
  padding: 4px 8px;
}

.details-container {
  padding: 1em;
  background-color: #888;
  border: 1px solid #000;
  border-radius: 3px;
  color: #fff;
  width: 200px;
}

.details-container h5 {
  display: block;
  margin: 0.5em;
}
.details-container p {
  display: block;
  margin: 0.5em;
}

.ibm-gantt-chart-div {
  min-height: 500px;
  height: 750px;
}

.activity-resources {
  padding: 4px;
  border: 1px solid #444;
  border-radius: 3px;
}

.activity-resources-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


a.gant-nav-btn {
  color: #fff;
}
