// .b-sch-event {
  //   background: #106cf6;
// }

.unscheduledNameCell {
  font-size: 12px;
}

.lookahead-wrapper {
  max-height: calc(100vh - 66px);
}

.lookahead-wrapper-daily-report {
  max-height: calc(100vh - 205px);
  overflow: hidden;
}

.lh-toolbar {
  border-bottom: 1px solid #ddd;
  height: 65px;
  justify-content: left;
  align-items: center;
  display: flex;
  padding: 0.5rem 2rem;
}

.lh-grid {
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.lh-grid-daily-report {
  max-height: calc(100vh - 205px);
  overflow: auto;
}

.lh-role {
  padding: 4px;
  background-color: #106cf6;
  border-radius: 3px;
  color: #fff;
  font-size: 0.8rem;
  margin-left: 4px;
}
.lh-utilization {
  padding: 4px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.8rem;
  margin-left: 4px;
  margin-right: 4px;
  &.lh-full {
    background-color: #008000;
  }
  &.lh-less {
    background-color: #FFAD52;
  }
  &.lh-over {
    background-color: #FF0000;
  }
}
.lh-name {
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lh-date-header-col {
  text-align: center;
}

.lh-timeline-label {
  line-height: 56px;
  margin-right: 8px;
}

.lh-task-card {
  padding: 4px 16px 4px 4px;
  border: 2px solid #5a9cff;
  border-radius: 4px;
  background-color: #d4e8ff;
  max-width: 250px;
  min-width: 250px;
  position: relative;
  min-height: 40px;
}

.lh-parent-task {
  font-weight: 600!important;
  color: #008f00;
  font-size: 0.8rem!important;
  line-height: 0.85rem;
}

.lh-child-task {
  background-color: #ddd;
  padding: 4px 0 4px 4px;
  border-radius: 4px;
  color: #5e6c43;
  font-size: 0.8rem!important;
  line-height: 0.85rem;
  margin-left: 4px!important;
}

.lh-task-chips {
  display: block;
  padding: 4px;
  &-chip {
    + .lh-task-chips-chip {
      margin-left: 4px;
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }
}

.lh-task-dropable-td {
  /*min-height: 120px;*/
  max-width: 280px;
  min-width: 255px;
  .lh-task-dropable-box {
    margin-bottom: 4px;
    margin-right: 4px;
  }
}

.lh-task-non-dropable-td {
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
  min-width: 255px;
  max-width: 280px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 85px;
  background: repeating-linear-gradient(-55deg,
      #eee,
      #eee 10px,
      #ddd 10px,
      #ddd 20px);

  .lh-task-dropable-box {
    margin-bottom: 4px;
    margin-right: 4px;
  }

}
.non-dropable-td {
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
}

.lh-task-dropable-td-flex {
  display: flex;
  flex-wrap: wrap;
}

.lh-task-dropable-td-1day {
  min-width: 60vw;
  display: flex;
  flex-wrap: wrap;
}

.lh-delete-assignment {
  position: absolute;
  right: 0;
  bottom: 0;
}

.lh-task-size-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

#lookahead {
  .b-widget-scroller {
    overflow: auto;
  }
  .b-grid-vertical-scroller {
    width: 170%;
  }
}

.lh-action-body {
  &-act-parent {
    color: #008f00;
    font-weight: bold;
  }
  &-act-child {
    background-color: #ddd;
    padding: 4px 0 4px 4px;
    border-radius: 4px;
    font-size: 0.8rem!important;
    line-height: 0.85rem;
  }
}

.lh-show-hide-columns {
  position: absolute;
  right: 0;
}

.lh-tasks-header-custom {
  .b-grid-header-text {
    padding: 4px 0;
    .lh-task-filter-custom {
      input {
        padding: 8px;
      }
    }
  }
}
