.green-bar {
  background-color: #66BB6A;
  border-color: #66BB6A;
}

.b-sch-event-wrap {
  border-radius: 4px;
}

// .b-sch-event {
//   border-radius: 4px;
//   font-weight: 400;
//   background-color: rgba(255, 255, 255, 0);
//   transition: background-color 0.2s;
//   color: #fff;
// }


.color-item {
  width: 0.9em;
  height: 0.9em;
  border-radius: 50%;
  margin-inline-end: 0.5em;
  position: relative;
  top: 0.2em;
}

.client-editor .b-popup-content {
  padding: 1em;
}

.b-highlighting .b-sch-event-wrap .b-sch-event {
  opacity: 0.2;
}

.b-highlighting .b-sch-event-wrap .b-sch-event.b-match {
  opacity: 1;
  box-shadow: rgb(255 82 82 / 65%) 0px 0px 4px 4px
}

.filter-task-input {
  .b-field-inner {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d1d2;
    min-width: 0;
    position: relative;

    .b-fieldtrigger {
      color: #b0b1b2;
      font-size: .9em;
      cursor: pointer;
      flex: 0 0 auto;
      &.b-align-start {
        padding-inline: 4px;
      }
    }

    input {
      background-color: transparent;
      color: inherit;
      padding: 4px;
      font-weight: 400;
      flex: 1 1 0;
      border: 0 none;
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      min-width: 1em;
      text-align: inherit;
    }
  }
}

.filter-col-name {
  margin: 0 0 0.5em;
  width: 100%;

  &.b-textfield {
    align-items: stretch;
    min-width: 3em;
    color: #4f5964;
    border-radius: 2px;
    position: relative;
    margin: 0;

    .b-field-inner {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      background-color: #fff;
      border-radius: 2px;
      border-width: 1px;
      border-style: solid;
      border-color: #d0d1d2;
      min-width: 0;
      position: relative;

      input {
        background-color: transparent;
        color: inherit;
        padding: 5px;
        font-weight: 400;
        -ms-flex: 1 1;
        flex: 1 1;
        border: 0 none;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        min-width: 1em;
        text-align: inherit;
      }
    }
  }
}

.col-header {
  .b-grid-header-text {
    padding: 4px 0;
  }
}

.filter-col-role {
  margin: 0 0 0.5em;
  width: 100%;

  &.b-filter-bar-field {
    margin: 0;
    align-items: stretch;
    min-width: 3em;
    color: #4f5964;
    border-radius: 2px;
    position: relative;

    .b-field-inner {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      background-color: #fff;
      border-radius: 2px;
      border-width: 1px;
      border-style: solid;
      border-color: #d0d1d2;
      min-width: 0;
      position: relative;

      ul {
        margin: 0;
        input {
          background-color: transparent;
          color: inherit;
          padding: 5px;
          font-weight: 400;
          -ms-flex: 1 1;
          flex: 1 1;
          border: 0 none;
          margin: 0;
          font-family: inherit;
          font-size: inherit;
          min-width: 1em;
          text-align: inherit;
        }
      }

      .b-align-end {
        padding-inline: 4px;
      }
    }
  }
}

.b-toolbar.scheduler-toolbar {
  border-bottom: 1px solid #ddd;
  .b-toolbar-content {
    min-height: 40px;
    padding: 5px;
  }
  .zoom-btns {
    min-height: 1em;
  }
}

.b-sch-nonworkingtime-canvas {
  .b-sch-range {
    background: repeating-linear-gradient(135deg, rgba(155, 155, 155, 0.1490196078), rgba(155, 155, 155, 0.1490196078) 4px, transparent 4px, transparent 8px);
  }
}