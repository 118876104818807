// .b-sch-event .b-sch-event-content {
//   font-size : 12px;
// }

.b-sch-color-gantt-green {
  background-color: #8ee997;
}

#gantt-chart-container > .b-container {
  flex-flow: row;
}
#schedulerPro-container > .b-container {
  flex-flow: row;
}

.res-assign-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: rgb(221 221 221 / 70%);
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.gantt-loading-state {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(221 221 221 / 70%);
  color: #000;
}

.selected-for-assignment {
  background: #bee2ff;
}

// dependencies
.b-sch-dependency {
  stroke-width: 1;
  stroke : #000;
}

.b-sch-dependency-arrow {
   fill: #000;
}

.b-sch-foreground-canvas > .b-tasknonworkingtime {
  background: rgba(221, 221, 221, 0.658);
  color: #83b5fe;
  transition: background 0.5s;
}
.b-sch-foreground-canvas > .weekends {
  background: repeating-linear-gradient(135deg, rgba(155, 155, 155, 0.1490196078), rgba(155, 155, 155, 0.1490196078) 4px, transparent 4px, transparent 8px);
}

.b-gantt-task > .b-tasknonworkingtime {
  background: repeating-linear-gradient(135deg, rgba(255, 255, 255, 0.2980392157), rgba(255, 255, 255, 0.2980392157) 4px, rgba(255, 0, 0, 0.2) 4px, rgba(255, 0, 0, 0.2) 8px);
}

.b-sch-foreground-canvas > .holiday {
  background: repeating-linear-gradient(135deg, rgba(255, 190, 190, 0.298), rgba(255, 169, 169, 0.298) 4px, rgba(255, 0, 0, 0.2) 4px, rgba(255, 0, 0, 0.2) 8px);
  // background: red;
}

.resources-list-wrapper {
  max-height: 65vh;
  overflow: auto;
}

.b-resource-avatar-container {
  .b-resource-initials {
    font-size: 11px;
  }
}

.b-task-baseline {
  background-color: red;
}

.save_changes {
  background-color: #8ee997;
}
