.resources-grid-wrapper {
  .resources-list-left {
    box-shadow: inset -8px -8px 10px -8px #444;
    height: calc(100vh - 64px);
    overflow: auto;
  }
  .resources-list-right {
    height: calc(100vh - 64px);
    overflow: auto;
  }
}

.drop-resource-area {
  border: 2px dashed #365ed6;
  margin-top: 2px;
  padding: 2px;
  text-align: center;
  border-radius: 4px;
  background: #cde9ff;
}

.drop-resource-area-wrapper {
  border: 2px dashed #365ed6;
  border-radius: 4px;
  background: #cde9ff;
  padding: 2px;
}

.resource-group-indicators {
  float: right;
  span.resource {
    background-color: #279d00;
    border-radius: 50%;
    color: #fff;
    padding: 4px 8px;
  }
  span.crew {
    background-color: #00259d;
    border-radius: 8px;
    color: #fff;
    padding: 4px 8px;
  }
}
.resource-group-actions {
  position: absolute;
  right: 0;
  top: 0;
}

.crew-group-box {
  min-width: 450px;
  min-height: 250px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #888;
  &-heading {
    text-align: center;
    background-color: #888;
    line-height: 2.5rem!important;
    .edit-btn {
      display: none;
    }
    &:hover {
      .edit-btn {
        display: inline-block;
      }
    }
  }
}

.resource-box {
  border: 1px solid #888;
  border-radius: 4px;
  display: inline-block;
  width: 270px;
  position: relative;
  &-text {
    display: inline-block;
    max-width: 70%;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } 
}

.resource-div {
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.gantt-loading-state {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(221 221 221 / 70%);
  color: #000;
}

.search-field {
  +.search-field {
    margin-top: 1em;
  }
}
