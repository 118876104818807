.report-data-card {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: flex;
  //justify-content: space-around;
  gap:5rem;
  box-shadow: 0px 0px 5px 4px #e3e3e3;
  &-box {
    h6 {
      font-weight: 600;
    }
    p {
      font-weight: 500;
      color: #333;
      font-size: 0.90rem;
    }
    + .report-data-card-box {
      border-left: 1px solid #ddd;
      padding-left: 0.5rem;
    }
  }
}